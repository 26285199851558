import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main/Main";
import Home from "../Pages/Home/Home"
import ContactUs from "../Pages/ContactUs/ContactUs";
import AboutUs from "../Pages/AboutUs/AboutUs";
import Portfolio from "../Pages/Portfolio/Portfolio";
import WebDev from "../Pages/WebDev/WebDev";
import AppDev from "../Pages/AppDev/AppDev";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Main></Main>,
        children: [
            {
                path: '/',
                element: <Home></Home>
            },
            {
                path: '/web-development',
                element: <WebDev></WebDev>
            },
            {
                path: '/app-development',
                element: <AppDev></AppDev>
            },
            {
                path: '/portfolio',
                element: <Portfolio></Portfolio>
            },
            {
                path: '/about-us',
                element: <AboutUs></AboutUs>
            },
            {
                path: '/contact-us',
                element: <ContactUs></ContactUs>
            },

        ],
    },

])

export default router;